var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"white rounded shadow1",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.getEmploySalesReport($event)}}},[_c('v-row',{staticClass:"flex_align_center flex_wrap",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto","md":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto","md":"6"}},[_c('v-row',{staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Date From")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.employe.dateFrom),callback:function ($$v) {_vm.$set(_vm.employe, "dateFrom", $$v)},expression:"employe.dateFrom"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('v-date-picker',{model:{value:(_vm.employe.dateFrom),callback:function ($$v) {_vm.$set(_vm.employe, "dateFrom", $$v)},expression:"employe.dateFrom"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto","md":"6"}},[_c('v-row',{staticClass:"pl-3",staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Date To")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.employe.dateTo),callback:function ($$v) {_vm.$set(_vm.employe, "dateTo", $$v)},expression:"employe.dateTo"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('v-date-picker',{model:{value:(_vm.employe.dateTo),callback:function ($$v) {_vm.$set(_vm.employe, "dateTo", $$v)},expression:"employe.dateTo"}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"text_bg_fave",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v("Search")])],1)],1),_c('v-divider')],1)],1)],1),(_vm.show)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Employee Name "+_vm._s(this.selectedEmployee.name))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"custom-data-table elevation-1",attrs:{"dense":"","headers":_vm.saleHeaders,"items":_vm.$store.getters['sale/empSaleReports'],"search":_vm.searchSale,"itemsPerPage":100},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mt-4",attrs:{"dense":"","color":"white","elevation":0}},[_c('v-toolbar-title',{staticClass:"subtitle-2"},[_vm._v("Sale Report")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-form',{staticClass:"custom-form"},[_c('v-text-field',{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search sale","append-icon":"mdi-magnify"},model:{value:(_vm.searchSale),callback:function ($$v) {_vm.searchSale=$$v},expression:"searchSale"}})],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.userType == 'super_admin' || _vm.userType == 'admin')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push(("/sale-invoice/" + (item.id)))}}},on),[_vm._v("mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("View Sale History")])]):_vm._e()]}}],null,true)},[_c('v-divider'),_c('template',{slot:"body.append"},[_c('tr',{staticClass:"pink--text"},[_c('th',{staticClass:"title",attrs:{"colspan":"3"}},[_vm._v("Totals of all("+_vm._s(_vm.lodash.size(_vm.$store.getters['sale/empSaleReports']))+") record")]),_c('th',{staticClass:"title"},[_vm._v(_vm._s(Number(_vm.sumField('total', _vm.$store.getters['sale/empSaleReports'])).toFixed(2)))]),_c('th',{staticClass:"title"},[_vm._v(_vm._s(Number(_vm.sumField('due', _vm.$store.getters['sale/empSaleReports'])).toFixed(2)))]),_c('th',{staticClass:"title"})])])],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }