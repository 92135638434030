<template>
    <v-container fluid class="white rounded shadow1">
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="getEmploySalesReport">
                    <v-row dense class="flex_align_center flex_wrap">
                        <v-col cols="auto" md="5">
                            <v-row no-gutters>
                                <v-col cols="auto" md="6">
                                    <v-row no-gutters style="margin-top: 3px;color: #000;">
                                        <v-col cols="4">Date From</v-col>
                                        <v-col cols="8">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="employe.dateFrom"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="employe.dateFrom"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="auto" md="6">
                                    <v-row class="pl-3" no-gutters style="margin-top: 3px;color: #000;">
                                        <v-col cols="4">Date To</v-col>
                                        <v-col cols="8">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="employe.dateTo"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="employe.dateTo"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="1">
                            <v-btn type="submit" class="text_bg_fave" :loading="loading">Search</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense v-if="show">
            <!-- <v-col cols="12">
                <v-btn height="26px" v-on:click.prevent="print">Print</v-btn>
            </v-col> -->
            <v-col cols="12">
                  <h3>Employee Name {{ this.selectedEmployee.name }}</h3>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="saleHeaders"
                    :items="$store.getters['sale/empSaleReports']"
                    :search="searchSale"
                    :itemsPerPage="100"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="0" class="mt-4">
                            <v-toolbar-title class="subtitle-2">Sale Report</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search sale"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchSale"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <v-divider></v-divider>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="userType == 'super_admin' || userType == 'admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="$router.push(`/sale-invoice/${item.id}`)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-eye </v-icon
                                >
                            </template>
                            <span>View Sale History</span>
                        </v-tooltip>
                    </template>
                    <template slot="body.append">
                        <tr class="pink--text">
                            <th class="title" colspan="3">Totals of all({{ lodash.size($store.getters['sale/empSaleReports']) }}) record</th>
                            <th class="title">{{ Number(sumField('total', $store.getters['sale/empSaleReports'])).toFixed(2) }}</th>
                            <th class="title">{{ Number(sumField('due', $store.getters['sale/empSaleReports'])).toFixed(2) }}</th>
                            <th class="title"></th>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'EmploySalesReport',
    data() {
        return {
            loading:false,
            show: false,
            searchSale: '',
            selectedEmployee: {},
            saleData:{},
            employe: {
                employeeId: this.$route.params.id,
                dateFrom: '',//new Date().toISOString().substr(0, 10),
                dateTo:'', //new Date().toISOString().substr(0, 10),
            },
            data:'',
            saleHeaders: [
            { text: 'Sl', value: 'sl' },
            { text: 'Customer Name', value: 'customer.name' },
            { text: 'Invoice', value: 'invoice' },
            { text: 'Total', value: 'total' },
            { text: 'Due', value: 'due' },
            // { text: 'Sub Total', value: 'sub_total' },
            { text: 'Action', value: 'action' },
            // { text: 'Details', value: 'data-table-expand' },
        ],
        }
    },
    created() {
        this.getEmploySalesReport();
        let userData = JSON.parse(localStorage.getItem('userData'))
		this.userType = userData.userType;
    },
    methods: {
        async getEmploySalesReport() {
            this.loading = true;
           await this.$store.dispatch('sale/employeeWaysReport', this.employe);
           this.selectedEmployee = await this.$store.getters['sale/selectedEmployee'];
            this.loading = false;

            this.show = true;

        },
        sumField(item_name, items=[]) {
            return items.reduce((a, b) => {
                let amount = 0;
                if (item_name == 'paid') {
                    amount = a + +b.payment?.amount || 0;
                } else {
                    amount = a + (Number(b[item_name]) || 0);
                }
                return amount;
            }, 0)
        },
    },
}
</script>
<style scoped>
    .container {
        width: 90%;
        margin: 0px auto;
    }
    .custom-row {
        width: 100%;
        display: block;
    }

    .print-btn a{
        background: #CFD8DC;
        display: inline-block;
        padding: 3px 13px;
        border-radius: 5px;
        color: #000 !important;
    }
    .print-btn a:hover {
        background: #B0BEC5;
    }
    .invoice-title {
        text-align: center;
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 15px;
        padding: 5px;
        border-top: 1px dotted #454545;
        border-bottom: 1px dotted #454545;
    }
    .col-xs-12 {
        width: 100%;
    }
    .col-xs-10 {
        width: 80%;
        float: left;
    }
    .col-xs-9 {
        width: 70%;
        float: left;
    }
    .col-xs-7 {
        width: 60%;
        float: left;
    }
    .col-xs-6 {
        width: 50%;
        float: left;
    }
    .col-xs-5 {
        width: 40%;
        float: left;
    }
    .col-xs-4 {
        width: 35%;
        float: left;
    }
    .col-xs-3 {
        width: 30%;
        float: left;
    }
    .col-xs-2 {
        width: 20%;
        float: left;
    }
    .b-text {
        font-weight: 500;
        font-size: 15px;
    }
    .normal-text {
        font-size: 14px;
        margin: 0px;
    }
    .invoice-details {
        width: 100%;
        border-collapse: collapse;
        border:1px solid #ccc;
    }
    .invoice-details thead {
        font-weight: 500;
        text-align:center;
    }
    .invoice-details tbody td{
        padding: 0px 5px;
    }
    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }
    .font-bold {
        font-weight: 700;
    }
    .line {
        border-bottom: 1px solid #ccc;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .paid-text {
        padding:30px 0px;
    }
    .mt-60 {
        margin-top: 60px;
    }
    .mr-20 {
        margin-right: 20px;
    }
    .ml-20 {
        margin-left: 20px;
    }
    .ft-fiext {
        position: fixed;
        bottom: 0;
    }
    .cls {
        clear: both;
    }
</style>
